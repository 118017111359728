import React from 'react';
import './Schedule.scss';
import Textbox from '../textbox/Textbox';

const Schedule = () => {
  return (
    <div className="schedule">
      <Textbox
        title="1900 - 2100: ARKIFEL"
        content={[
          `Arkifel brings us a set full of electro and bass house guaranteed to get your booty moving! They've played at Otterdance, BunkerBeats, Alfurnative, and hacker events around Amsterdam. Arkifel also produces house music and chiptunes, so you might hear a few fresh tracks too. You don’t want to miss it!`,
        ]}
        // WARNING: add correct link
        link={{ href: 'https://soundcloud.com/arkifel', title: 'PEERTUBE' }}
      />
      <Textbox
        title="2100 - 2300: VEGAZ"
        content={[
          `Vegaz is  known for his previous work at NFC, Otterdance and Pawske party! With his various styles of Drum & Bass, ranging from Belgian jump all the way to some calmer liquids, he is sure to get all of you swinging!`,
        ]}
        // WARNING: add correct link
        link={{ href: 'https://soundcloud.com/arkifel', title: 'PEERTUBE' }}
      />
      <Textbox
        title="2300 - 0100: STOEIPOES"
        // WARNING: add correct content
        content={[
          `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.`,
        ]}
        // WARNING: add correct link
        link={{ href: 'https://soundcloud.com/arkifel', title: 'PEERTUBE' }}
      />
    </div>
  );
};

export default Schedule;
