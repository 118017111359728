import React from 'react';
import './Previous.scss';
import imgplaceholder from './Images/odplaceholder.png';

const Previous = () => {
  return (
    <div className="previous">
      <div className="edition">
        <img src={imgplaceholder} alt="Otterdance 3" />
        <h3>OTTERDANCE 3 - ROTTERDAM</h3>
      </div>
      <div className="edition">
        <img src={imgplaceholder} alt="Otterdance 4" />
        <h3>OTTERDANCE 4 - AMSTERDAM</h3>
      </div>
    </div>
  );
};

export default Previous;
