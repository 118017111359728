import React from 'react';
import './Tips.scss';
import Textbox from '../textbox/Textbox';

const Tips = () => {
  return (
    <div className="tips">
      <Textbox
        title="TRAVEL"
        content={[
          `We very strongly advise you to take the train to Amsterdam Centraal. Night trains normally run from Rotterdam Centraal through Amsterdam to Utrecht and back again. check the NS website, especially for your journey after the event. International trains are at nsinternational.nl and Google Flights is a good place to start for cheap(er) fares.`,
          `Flixbus is another option that includes international destinations. It's often cheaper. Search several bus and train lines with Wanderu.`,
          `Parking in the immediate area is extremely expensive, typically costing about €55 for the event. If you drive, you can unload passengers/fursuits/equipment very near the venue.`,
          `Inner Amsterdam is one of the world's most popular tourist destinations, especially for “partying” visitors. It's generally safe, however you might encounter obnoxious behaviour.`,
        ]}
      />
      <Textbox
        title="ACCOMMODATION"
        content={[
          `Nearby accommodations are extremely expensive: Hotels typically cost €200-300, and Even a hostel bed is €90.`,
          `If you're willing to take the free, all-night ferry across the river, ClinkNOORD Hostel is a cheap exception, with beds around €50/night. Otherwise, alternatives include sharing a room or staying outside the city (in a place with good night transit).`,
        ]}
      />
    </div>
  );
};

export default Tips;
