import React from 'react';
import './Infocards.scss';

const Infocards = () => {
  return (
    <div className="cards">
      <div className="card">
        <h2>WHAT</h2>
        <p>
          Get ready to experience the ultimate fusion of fur and beats at the
          next Otterdance.
        </p>
        <p>
          Step into a world where your fursona comes alive, and the dance floor
          is your playground. Whether you&apos;re a seasoned raver or new to the
          scene, this electrifying night is your chance to unleash your wild
          side in an unforgettable celebration of music, color, and community.
        </p>
        <p>
          Join hundreds of furries from all walks of life as we dance to the
          hottest electronic beats, spun by top DJs who know how to keep the
          energy soaring all night long. The lights will pulse, the bass will
          drop, and the crowd will go wild as you lose yourself in the rhythm
          and let your fursona take the lead.
        </p>
        <p>
          This is your moment to shine, connect with fellow furries, and make
          memories that’ll last a lifetime.
        </p>
      </div>
      <div className="card">
        <h2>WHERE</h2>
        <p>
          Akhnaton is within an easy walk from Amsterdam Centraal and has a
          fascinating history. Otterdance 5 is located at Nieuwezijds Kolk 25,
          1012 PV Amsterdam.
        </p>
        <img
          // WARNING: this is a placeholder, please replace!!!
          src="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
          alt="Akhnaton"
        />
      </div>
      <div className="card">
        <h2>WHO</h2>
        <p>
          Stichting Otterdam makes &quot;furry&quot; culture more accessible. It
          both produces events based in the furry community but open to all
          (such as its flagship club night, &quot;Otterdance&quot;) and helps
          non-furry institutions explore anthropomorphic arts through outreach,
          co-productions, and practical support. Stichting Otterdam concerns
          anthropomorphic arts and entertainment, commonly known as
          &quot;furry&quot;. Through furry arts, people find new ways to see
          themselves and others by mixing self-image with animal archetypes.
        </p>
        <p>You can find us at:</p>

        <div className="social">
          <img
            // WARNING: this is a placeholder, please replace!!!
            src="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
            alt="Telegram"
          />
          <p>@Otterdance</p>
        </div>
        <div className="social">
          <img
            // WARNING: this is a placeholder, please replace!!!
            src="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
            alt="Bluesky"
          />
          <p>@otterdam.bsky.social</p>
        </div>
        <div className="social">
          <img
            // WARNING: this is a placeholder, please replace!!!
            src="https://otterdam.org/images/202404-stichting-otterdam-logo-final-transparent.png"
            alt="Mastodon"
          />
          <p>@otterdance@furrycon.social</p>
        </div>
      </div>
    </div>
  );
};

export default Infocards;
