import React from 'react';
import './Hero.scss';
import { Countdown } from '@otterdam/otterdam-components';
import danceotter from './Images/danceotterplaceholder.png';

const Hero = () => {
  return (
    <div className="hero">
      <div className="left-header">
        <h1>OTTERDANCE 5: AMSTERDANCE!</h1>
        <h2>From Stichting Otterdam</h2>
        <p>
          For the fifth edition of Otterdance, stichting Otterdam would like to
          invite you to rave at Akhnaton in Amsterdam on December 14th, 2024!
        </p>
        <p>
          Get your dancing clothes ready, because at Otterdance 5, we will party
          till the late night!
        </p>
        <p>Are you ready for the next Otterdance?</p>

        {/* Countdown Component */}
        <Countdown targetDate="2025-01-01T00:00:00">
          <div>
            <h2>The countdown is over!</h2>
          </div>
        </Countdown>

        <a
          className="buy-ticket-button"
          // WARNING: this is a placeholder, please replace!!!
          href="https://www.youtube.com/watch?v=lZbfNtDCHdM"
          target="_blank"
          rel="noopener noreferrer"
        >
          BUY YOUR TICKET HERE
        </a>
      </div>

      <div className="right-header">
        <img
          // WARNING: this is a placeholder, please replace!!!
          src={danceotter}
          alt="Dancing Otterdam otter"
        />
      </div>
    </div>
  );
};
export default Hero;
